<template>
    <div class="flex flex-col bg-slate-100">
        <div class="flex items-center justify-between p-6 border-b shadow">
            <div
                v-if="parentParticipant"
                class="flex"
            >
                <UiAvatar
                    :path="parentParticipant?.user?.avatar_path"
                    class="w-6 h-6"
                />
                <div class="ml-2 font-semibold text-gray-900">
                    <div>{{ parentParticipant?.user?.first_name }} {{ parentParticipant?.user?.last_name }}</div>
                    <div
                        v-if="parent"
                        class="text-xs text-gray-500"
                    >
                        {{ parent.babies[0].parent_type }} de {{ parent.babies.map(b => b.name).join(', ') }}
                    </div>
                </div>
            </div>
            <div
                v-else
                class="flex"
            >
                <div class="ml-2 font-semibold text-gray-900">
                    <div>Discussion</div>
                </div>
            </div>
            <UiDropdown
                class="relative"
                :close-on-click-outside="false"
            >
                <template #trigger>
                    <button class="flex items-center space-x-1 text-gray-400 hover:text-gray-600 transition-all">
                        <UsersIconMini class="size-4" />
                        <ChevronDownIconMini class="size-4" />
                    </button>
                </template>
                <template #content>
                    <div class="w-auto text-xs text-right text-gray-600 dropdown-menu">
                        <div class="pb-2 text-center text-gray-700 border-b">
                            Participants:
                        </div>
                        <ul class="px-2 divide-y">
                            <li class="py-2">
                                <UiButtonModal
                                    id="chat-thread-add-participant"
                                    no-background
                                    size="3xl"
                                >
                                    <template #trigger>
                                        <button class="text-blue-500 hover:text-blue-600">
                                            + Ajouter un participant
                                        </button>
                                    </template>
                                    <chat-thread-add-participant
                                        :thread="thread"
                                        @updated="fetchMessages"
                                    />
                                </UiButtonModal>
                            </li>
                            <li
                                v-for="participant in thread.participants"
                                :key="participant.user.email"
                                class="py-2"
                            >
                                <div class="text-sm mb-1s">
                                    {{ participant.user.first_name }} {{ participant.user.last_name }}
                                </div>
                                <div>{{ participant.user.email }}</div>
                            </li>
                        </ul>
                    </div>
                </template>
            </UiDropdown>
        </div>

        <div class="grow overflow-auto">
            <div
                id="messagesContainer"
                class="flex flex-col-reverse px-3 py-6"
            >
                <div
                    v-for="message in messages"
                    :key="message.id"
                >
                    <div class="clearfix">
                        <div
                            class="w-3/4 my-2"
                            :class="{
                                'ml-auto text-right': message.user.uuid === $user.uuid,
                                'mr-auto': message.user.uuid !== $user.uuid
                            }"
                        >
                            <div
                                class="relative inline-block pt-2 pb-2 pl-2 pr-6 text-sm text-left rounded-lg bg-opacity-20"
                                style="min-width:100px; word-break: break-word;"
                                :class="{
                                    'bg-secondary-500 ml-auto': message.user.uuid === $user.uuid,
                                    'bg-gray-500 mr-auto': message.user.uuid !== $user.uuid
                                }"
                            >
                                <div v-if="message.media && message.media.length">
                                    <AttachmentMedia
                                        v-for="media in message.media"
                                        :key="media.id"
                                        :media="media"
                                        size="auto"
                                    />
                                </div>
                                <div
                                    v-else
                                    class="whitespace-pre-line"
                                    v-text="message.body"
                                />
                                <div
                                    class="pr-2 text-right text-gray-500"
                                    style="font-size: 9px;"
                                >
                                    <span
                                        v-if="thread.participants.length > 2 && message.user.uuid !== $user.uuid"
                                    >
                                        {{ message.user.first_name }} {{ message.user.last_name }} &middot;
                                    </span>
                                    <span>{{ messageTime(message.created_at) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="isLoading"
                    class="pr-4 mt-4 text-right"
                >
                    <span class="loading gray" />
                </div>
                <button
                    v-else-if="hasMorePage"
                    class="btn btn-link w-full justify-center"
                >
                    Voir plus...
                </button>
            </div>
        </div>

        <form
            class="flex items-center px-1 py-2"
            @submit.prevent="storeMessage"
        >
            <label
                for="fileinput"
                class="relative flex items-end transition-all rounded-full hover:bg-primary-100 bg-transparent justify-center px-2 py-2 text-gray-500 cursor-pointer"
            >
                <input
                    id="fileinput"
                    type="file"
                    class="w-0 h-0 opacity-0 cursor-pointer"
                    name="file"
                    @change="onFileChange"
                >
                <PaperClipIconSolid class="size-5" />
            </label>
            <label
                v-if="inTabletLayout"
                class="relative flex items-end transition-all rounded-full hover:bg-primary-100 bg-transparent justify-center mr-2 px-2 py-2 text-gray-500 cursor-pointer"
                for="file_camera"
            >
                <input
                    id="file_camera"
                    accept="image/*"
                    capture="environment"
                    class="w-0 h-0 opacity-0 cursor-pointer"
                    multiple
                    name="file"
                    type="file"
                    @change="onFileChange"
                >
                <CameraIconSolid class="size-5" />
            </label>
            <div
                v-if="selectedFile"
                class="flex justify-center w-full px-3 py-2 bg-white rounded-full"
            >
                <AttachmentPreview
                    :file="selectedFile"
                    @remove="selectedFile = null"
                />
            </div>
            <input
                v-else
                v-model="formData.message"
                name="message"
                class="w-full px-3 py-2 text-sm border-0 rounded-full appearance-none focus:outline-none focus:ring-0"
                required
                type="text"
                autofocus
                placeholder="Tapez un message"
                @keydown="handleEnter"
            >
            <button
                type="submit"
                class="flex-shrink-0 ml-2 rounded-full btn btn-primary btn-icon"
                :disabled="isLoading || (!formData.message && !selectedFile)"
                :class="{ loading: isLoading }"
            >
                <PaperAirplaneIconMini class="size-5" />
            </button>
        </form>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import { loadingMixin } from 'abcreche-ui'
import parentOptions from '~/mixins/parents/parentOptions'
import ChatRepository from '~/repositories/Shared/ChatRepository.js'

export default {
    mixins: [loadingMixin, parentOptions],

    props: {
        thread: {
            type: Object,
            required: true
        }
    },
    emits: ['read'],

    data () {
        return {
            formData: {
                message: null
            },
            selectedFile: null,
            messages: [],
            page: 1,
            hasMorePage: true
        }
    },

    computed: {
        parentParticipant () {
            return this.thread.participants.find((participant) => {
                return participant.user.uuid !== this.$user.uuid
                    && !participant.user.current_creche_uuid
            }) || this.thread.participants.find((participant) => {
                return participant.user.uuid !== this.$user.uuid
            })
        },

        parent () {
            if (this.parentParticipant?.user) {
                return this.parents.filter(p => p.user).find(p => p.user.uuid === this.parentParticipant.user.uuid)
            }
            return null
        }
    },

    created () {
        this.$echo.private('messages.thread.' + this.thread.id)
            .on('message.created', (e) => {
                this.fetchMessages()
            })

        this.fetchMessages()
    },

    methods: {
        fetchMessages (append = true) {
            this.load()
            this.$modal.close('chat-thread-add-participant')
            ChatRepository.fetchMessagesFromThread(this.thread.id, { page: this.page })
                .then((response) => {
                    this.messages = append ? [...response.data, ...this.messages] : response.data
                    this.hasMorePage = response.meta.current_page < response.meta.last_page
                    this.done()

                    this.scrollToLastMessage()

                    this.$emitter.emit('chat:read', this.thread.id)
                })
                .catch(error => this.pageError(error))
        },

        onFileChange (event) {
            this.selectedFile = event.target.files[0]
        },

        storeMessage () {
            this.load()
            if (this.selectedFile) {
                this.storeFileMessage()
            } else {
                const payload = { ...this.formData }
                ChatRepository.postMessage(this.thread.id, payload)
                    .then(() => {
                        this.formData.message = ''
                        this.fetchMessages()
                        this.done()
                    })
                    .catch(error => this.pageError(error))
            }
        },

        storeFileMessage () {
            const formData = new FormData()
            formData.append('message', this.selectedFile.name)
            if (this.selectedFile) {
                formData.append('file', this.selectedFile)
            }
            ChatRepository.postMessage(this.thread.id, formData)
                .then(() => {
                    this.formData.message = ''
                    this.selectedFile = null
                    this.done()
                    this.fetchMessages()
                })
                .catch(error => this.pageError(error))
        },

        handleEnter (e) {
            if (e.keyCode === 13 && !e.shiftKey) {
                e.preventDefault()

                const message = this.formData.message ? this.formData.message.trim() : null

                if (message) {
                    this.storeMessage(message)
                    this.formData.message = null
                }

                return false
            }
        },

        messageTime (datetime) {
            if (dayjs(datetime).isSame(dayjs(), 'day')) {
                return dayjs(datetime).format('HH:mm')
            } else if (dayjs(datetime).isSame(dayjs(), 'year')) {
                return dayjs(datetime).format('DD MMM HH:mm')
            } else {
                return dayjs(datetime).format('DD/MM/YYYY HH:mm')
            }
        },

        scrollToLastMessage () {
            setTimeout(() => {
                this.$el.querySelector('#messagesContainer').scrollIntoView({
                    behavior: 'smooth',
                    block: 'end'
                })
            }, 100)
        }
    }
}
</script>
